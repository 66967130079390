.bubble {
  background: linear-gradient(135deg, #d2a679 0%, #b67d4e 100%); /* צבעי גרדיאנט חום קפה */
  border-radius: 30px 30px 30px 5px;
  padding: 25px;
  width: 300px; /* הגודל המקורי */
  margin: 15px;
  box-shadow: 0 4px 10px rgba(68, 68, 68, 0.15);
  transition: all 0.3s ease;
  direction: rtl;
  position: relative;
  overflow: hidden;
}

.bubble::before {
  content: '';
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 50px; /* הגודל המקורי */
  height: 50px; /* הגודל המקורי */
  background: #ffffff; /* חום כהה */
  border-radius: 50%;
  opacity: 0.5;
}

.bubble:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(68, 68, 68, 0.2);
}

.lessonNumber {
  font-size: 1.2rem;
  font-family: "Assistant";
  color: #ffffff;
  margin-bottom: 10px;
}

.content {
  position: relative;
  z-index: 1;
}

.title {
  font-size: 1.4rem;
  font-family: "Assistant";
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #fff9f7;
  margin-bottom: 10px;
}

.description {
  font-size: 1.05rem;
  color: #000000;
  font-family: "AssistantR";
  line-height: 1.4;
}

@media only screen and (max-width: 350px) {
  .bubble {
    width: 80%;
    padding: 20px;
    margin: 10px auto;
  }
  .lessonNumber { font-size: 1.1rem; }
  .title { font-size: 1.25rem; }
  .description { font-size: 0.9rem; }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .bubble {
    width: 80%;
    padding: 22px;
    margin: 12px auto;
  }
  .lessonNumber { font-size: 1.1rem; }
  .title { font-size: 1.3rem; }
  .description { font-size: 0.9rem; }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .bubble {
    width: 75%;
    padding: 23px;
    margin: 13px auto;
  }
  .lessonNumber { font-size: 1.15rem; }
  .title { font-size: 1.35rem; }
  .description { font-size: 0.92rem; }
}
