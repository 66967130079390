.socialContainer {
    display: flex;
    justify-content: center; /* Center horizontally */
  }
  
  .socialLinks {
    display: flex;
    width:50%;
    justify-content: space-evenly; /* Distribute links evenly */
    align-items: center; /* Center icons vertically */
  }
  
  .socialCircle {
    width: 100px;
    height: 100px;

    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 100px;
    font-size: 35px;
  }
  .title {
    font-size: 1.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 40%;
}
.description {
    font-size: 1.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 40%;
}