.title {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1% auto;
    width: 100%;
    justify-content: space-evenly;
}

.description {
    font-size: 1.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 40%;
}

.center {
    display: flex;
    margin: 0 auto;
    width: 20%;
    justify-content: center;
}

.bold {
    display: inline;
    font-family: "Assistant";
}

@media only screen and (max-width: 350px) {
    .title { font-size: 2rem;margin:10px auto }
    .description { 
        font-size: 1.1rem;
        width: 90%;
    }
    .center { width: 80%; }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title { font-size: 2.3rem; margin:10px auto }
    .description { 
        font-size: 1.1rem;
        width: 85%;
    }
    .center { width: 45%; }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title { font-size: 2.6rem; margin:10px auto }
    .description { 
        font-size: 1.2rem;
        width: 80%;
    }
    .center { width: 40%; }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title { font-size: 2.9rem; margin:10px auto }
    .description { 
        font-size: 1.25rem;
        width: 85%;
    }
    .center { width:65%; }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title { font-size: 3.2rem; margin:10px auto }
    .description { 
        font-size: 1.3rem;
        width: 80%;
    }
    .center { width: 60%; }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title { font-size: 3.5rem; }
    .description { 
        font-size: 1.4rem;
        width: 70%;
    }
    .center { width: 25%; }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .title { font-size: 3.7rem; }
    .description { 
        font-size: 1.45rem;
        width: 65%;
    }
    .center { width: 22%; }
}

@media only screen and (min-width: 950px) {
    .title { font-size: 4rem; }
    .description { 
        font-size: 1.5rem;
        width: 60%;
    }
    .center { width: 20%; }
}