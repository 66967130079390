.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1% auto;
    direction: rtl;
    justify-content: space-evenly;
}

.title {
    font-family: "Assistant";
    text-align: center;
    margin: 1% auto;
    font-size: 3rem;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.description {
    font-family: "AssistantR";
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    font-size: 1.2rem;
    width: 70%;
}

/* Max width 350px */
@media (max-width: 350px) {
    .title {
        font-size: 2rem;
    }
    .description {
        font-size: 1rem;
        width: 90%;
    }
}

/* Min width 350px and max width 450px */
@media (min-width: 351px) and (max-width: 450px) {
    .title {
        font-size: 2.5rem;
    }
    .description {
        font-size: 1.1rem;
        width: 85%;
    }
}

/* 450-550px */
@media (min-width: 451px) and (max-width: 550px) {
    .title {
        font-size: 3rem;
    }
    .description {
        font-size: 1.2rem;
        width: 80%;
    }
}

/* 550-650px */
@media (min-width: 551px) and (max-width: 650px) {
    .title {
        font-size: 3.2rem;
    }
    .description {
        font-size: 1.3rem;
        width: 75%;
    }
}

/* 650-750px */
@media (min-width: 651px) and (max-width: 750px) {
    .title {
        font-size: 3.4rem;
    }
    .description {
        font-size: 1.35rem;
    }
}

/* 750-850px */
@media (min-width: 751px) and (max-width: 850px) {
    .title {
        font-size: 3.6rem;
    }
    .description {
        font-size: 1.4rem;
    }
}

/* 850-950px */
@media (min-width: 851px) and (max-width: 950px) {
    .title {
        font-size: 3.7rem;
    }
    .description {
        font-size: 1.45rem;
    }
}

/* 950-1050px */
@media (min-width: 951px) and (max-width: 1050px) {
    .title {
        font-size: 3.8rem;
    }
    .description {
        font-size: 1.47rem;
    }
}

/* 1050-1150px */
@media (min-width: 1051px) and (max-width: 1150px) {
    .title {
        font-size: 3.9rem;
    }
    .description {
        font-size: 1.48rem;
    }
}

/* 1150-1250px */
@media (min-width: 1151px) and (max-width: 1250px) {
    .title {
        font-size: 4rem;
    }
    .description {
        font-size: 1.5rem;
    }
}